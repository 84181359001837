import React, { ComponentProps, FC } from 'react'
import { getEventHomeAwayCompetitors } from 'astra-core'
import { Event } from 'betweb-openapi-axios'

import {
  EventTeamsNamesCompactView as EventTeamsNamesEntityCompactView,
  EventTeamsNames as EventTeamsNamesEntity
} from 'entities/event'
import { EventRowOpenEventPage } from 'features/event'
import { ETagTypes, Tag } from 'shared/ui/Tag'
import { useIsSmallMedium } from 'shared/lib/styled'

import { useGetIsActivePlayer } from '../../lib/teams-names'

import { StyledTeamWrapper } from './teams-names.styled'

export const EventRowTeamsNames: FC<{ event: Event }> = ({ event }) => {
  const { homeCompetitors, awayCompetitors } =
    getEventHomeAwayCompetitors(event)
  const { isActiveHomeTeam, isActiveAwayTeam } = useGetIsActivePlayer({
    eventStatistics: event.statistics
  })

  const teams: ComponentProps<typeof EventTeamsNamesEntity>['teams'] = [
    {
      name: homeCompetitors.map((c) => c.name).join(', '),
      isActivePlayer: isActiveHomeTeam
    },
    {
      name: awayCompetitors.map((c) => c.name).join(', '),
      isActivePlayer: isActiveAwayTeam
    }
  ]

  const isCompactView = useIsSmallMedium()

  return (
    <>
      {isCompactView ? (
        <EventTeamsNamesEntityCompactView
          awayTeamWrapper={(teams) => (
            <TeamWrapper event={event} marqueeId={awayCompetitors[0]?.id}>
              {teams}
            </TeamWrapper>
          )}
          homeTeamWrapper={(teams) => (
            <StyledTeamWrapper>
              <TeamWrapper event={event} marqueeId={homeCompetitors[0]?.id}>
                {teams}
              </TeamWrapper>
              {event.top && <Tag type={ETagTypes.TOP} />}
            </StyledTeamWrapper>
          )}
          teams={teams}
        />
      ) : (
        <EventTeamsNamesEntity
          teamsWrapper={(teams) => (
            <TeamWrapper event={event}>{teams}</TeamWrapper>
          )}
          tagComponent={event.top && <Tag type={ETagTypes.TOP} />}
          teams={teams}
        />
      )}
    </>
  )
}

export const TeamWrapper: FC<{
  event: Event
  marqueeId?: number
  children
}> = ({ children, event, marqueeId }) => (
  <EventRowOpenEventPage event={event} marqueeId={marqueeId}>
    {children}
  </EventRowOpenEventPage>
)

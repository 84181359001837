import React, { FC, memo, useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'
import { selectBroadcastAvailabilityByEventId } from 'astra-core/containers/BroadcastProvider/selectors'

import { IconLiveTracker } from 'shared/ui/Icon/General/IconLiveTracker'
import { IconStream } from 'shared/ui/Icon/General/IconStream'
import { IconH2H } from 'shared/ui/Icon/General/IconH2H'
import { matchTrackerModel } from 'entities/match-tracker'
import { getEventBetradarSourceId } from 'shared/lib/events'
import {
  EventContext,
  IEventContext
} from 'pages/page-live-events/Line/components/Event/Event.context'
import { matchBroadcastModel } from 'entities/match-broadcast'
import { useAppSelector } from 'app/lib/store'

import { ICON_ACTION_PROPS } from './constants'
import {
  ActionItemProps,
  ActionLiveTrackerProps,
  ActionStatisticsProps,
  ActionsListProps
} from './Actions.types'
import { StyledActions, StyledActionsItem } from './Actions.styled'

export const ActionsList: FC<ActionsListProps> = memo(({ sportCode }) => {
  const { event, isSpecialStatus } = useContext(EventContext) as IEventContext

  const hasBroadcast = useAppSelector((state) =>
    selectBroadcastAvailabilityByEventId(state, event.id)
  )
  const isFeatureFlagEnabled = useFeatureFlag(EFeatureFlags.BROADCAST_ENABLED)
  const broadcastEnabled = event.live && hasBroadcast && isFeatureFlagEnabled

  let eventBannerActions: JSX.Element | null = (
    <>
      {event.live && <ActionLiveTraker event={event} />}
      {broadcastEnabled && <ActionBroadcast event={event} />}
    </>
  )
  if (isSpecialStatus) {
    eventBannerActions = null
  }

  return (
    <StyledActions sportCode={sportCode}>
      {/* <ActionItem Icon={IconStatistics} title="statistics" /> */}

      {eventBannerActions}

      {!event.live && <ActionH2H event={event} />}
    </StyledActions>
  )
})

const ActionItem: FC<ActionItemProps> = ({ title, Icon, ...props }) => {
  const [t] = useTranslation()

  return (
    <StyledActionsItem {...props}>
      <Icon {...ICON_ACTION_PROPS} />

      {t(title)}
    </StyledActionsItem>
  )
}

const ActionH2H: FC<ActionStatisticsProps> = ({ event }) => {
  const eventSourceId = useMemo(() => getEventBetradarSourceId(event), [event])

  const handleClick = useCallback(
    () => window.open(`/headToHead/${eventSourceId}`, 'head2head'),
    [eventSourceId]
  )

  if (!eventSourceId) {
    return null
  }

  return <ActionItem Icon={IconH2H} title="H2H" onClick={handleClick} />
}

const ActionLiveTraker: FC<ActionLiveTrackerProps> = ({ event }) => {
  const dispatch = useDispatch()
  const eventSourceId = useMemo(() => getEventBetradarSourceId(event), [event])

  const handleClick = useCallback(() => {
    if (eventSourceId) {
      dispatch(
        matchTrackerModel.liveMatchTrackerActions.setCurrentMatch({
          eventSourceId
        })
      )
    }
  }, [dispatch, eventSourceId])

  if (!eventSourceId) {
    return null
  }

  return (
    <ActionItem
      Icon={IconLiveTracker}
      title="live tracker"
      onClick={handleClick}
    />
  )
}

const ActionBroadcast: FC<ActionLiveTrackerProps> = ({ event }) => {
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(
      matchBroadcastModel.liveMatchBroadcastActions.setCurrentBroadcast({
        eventId: event.id
      })
    )
  }

  return <ActionItem Icon={IconStream} title="stream" onClick={handleClick} />
}

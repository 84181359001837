import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  StyledOutcomesHead,
  StyledOutcomesHeadRight,
  StyledOutcomesToggleDisplay
} from 'pages/page-live-events/Line/components/Outcomes/Outcomes.styled'
import { OutcomesGroupsTabs } from 'pages/page-live-events/Line/components/Outcomes/Filters/components/OutcomesFilterTabs'
import { InputSearch } from 'pages/page-live-events/Line/components/Event/components'
import { ETestData } from 'shared/lib/testData'

import { OutcomesHeadProps } from './outcomes-head.types'

export const OutcomesHead: FC<OutcomesHeadProps> = ({
  event,
  areAllOpened,
  openAll,
  closeAll
}) => {
  const [t] = useTranslation()

  return (
    <StyledOutcomesHead>
      <OutcomesGroupsTabs event={event} />

      <StyledOutcomesHeadRight>
        <InputSearch
          eventId={event.id}
          testData={ETestData.TestEventRowInputSearch}
        />

        <StyledOutcomesToggleDisplay
          data-test-id={
            areAllOpened
              ? ETestData.TestEventRowFilterCollapseAll
              : ETestData.TestEventRowFilterExpandAll
          }
          onClick={() => (areAllOpened ? closeAll() : openAll())}
        >
          {t(areAllOpened ? 'collapse all' : 'expand all')}
        </StyledOutcomesToggleDisplay>
      </StyledOutcomesHeadRight>
    </StyledOutcomesHead>
  )
}

import React, { FC, memo, useState } from 'react'
import { Event } from 'betweb-openapi-axios'
import { selectEventById } from 'astra-core/containers/EventsProvider/selectors'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { RootState } from 'shared/types/store'

import { EventRow } from '../event-row'

import {
  StyledExpandButton,
  StyledLinkedEventsWrapper,
  StyledRowWrapper
} from './linked-events.styled'
import { LinkedEventProps, LinkedEventsProps } from './linked-events.types'

const firstExpandMaxCount = 5

const LinkedEvent: FC<LinkedEventProps> = memo(({ id, parentEvent }) => {
  const event = useSelector((state: RootState) => selectEventById(state, id))

  return (
    <StyledRowWrapper>
      <EventRow event={event} key={event.id} parentEvent={parentEvent} />
    </StyledRowWrapper>
  )
})

export const LinkedEvents: FC<LinkedEventsProps> = memo((props) => {
  const { parentEvent } = props
  const [isSecondExpandOpened, setIsSecondExpandOpened] = useState(false)
  const { t } = useTranslation()

  if (!parentEvent?.linkedEvents) {
    return null
  }

  const handleSecondExpand = () => {
    setIsSecondExpandOpened(true)
  }

  const linkedEventsData = parentEvent.linkedEvents.reduce<{
    firstExpand: Event['id'][]
    secondExpand: Event['id'][]
  }>(
    (acc, event, index) => {
      if (index < firstExpandMaxCount) {
        acc?.firstExpand?.push(event.eventId)
        return acc
      }
      acc?.secondExpand?.push(event.eventId)
      return acc
    },
    { firstExpand: [], secondExpand: [] }
  )

  const { firstExpand, secondExpand } = linkedEventsData

  const showMore = secondExpand.length > 0
  return (
    <StyledLinkedEventsWrapper>
      {firstExpand.map((eventId) => (
        <LinkedEvent id={eventId} key={eventId} parentEvent={parentEvent} />
      ))}
      {showMore && (
        <StyledExpandButton
          hidden={isSecondExpandOpened}
          onClick={handleSecondExpand}
        >
          {t('show more events', {
            count: secondExpand.length
          })}
        </StyledExpandButton>
      )}
      {isSecondExpandOpened &&
        secondExpand.map((eventId) => (
          <LinkedEvent id={eventId} key={eventId} parentEvent={parentEvent} />
        ))}
    </StyledLinkedEventsWrapper>
  )
})

import { PayloadAction } from '@reduxjs/toolkit'
import isEqual from 'lodash/isEqual'
import { isFeatureFlagEnabled } from 'astra-core/containers/ConfigProvider/utils'
import { EFeatureFlags } from 'astra-core/containers/ConfigProvider/types'
import { logger } from 'astra-core/utils/logger'
import { ErrorPayload } from 'astra-core/typings/api'

import { createSlice } from 'shared/lib/@reduxjs/toolkit'
import { requestInitialState } from 'shared/lib/api'
import { getPreloadedState, toIdsArray } from 'shared/lib/@reduxjs'

import {
  ContainerState,
  FetchLivePayload,
  FetchLiveSuccessPayload,
  TResetLiveFilter
} from './types'

const REDUCER_KEY = 'liveContainer'

// The initial state of the LiveContainer container
export const initialState: ContainerState = getPreloadedState(REDUCER_KEY, {
  eventsIdsBySport: {},
  fetchItems: requestInitialState,
  filters: {
    isTop: false
  },
  layouts: {
    isLoading: false
  }
})

const liveContainerSlice = createSlice({
  name: REDUCER_KEY,
  initialState,
  reducers: {
    fetchLive(state, _action: PayloadAction<FetchLivePayload>) {
      state.fetchItems.loading = true
      state.fetchItems.error = null
    },
    fetchLiveSuccess(state, action: PayloadAction<FetchLiveSuccessPayload>) {
      const { sportId } = action.payload.reqData
      const { items } = action.payload.resData

      state.fetchItems.loading = false
      state.fetchItems.error = null

      if (sportId) {
        const shouldCompareEveryEvent = isFeatureFlagEnabled(
          EFeatureFlags.PERFORMANCE_PLAT628
        )
        if (shouldCompareEveryEvent) {
          const start = Date.now()
          const newIdsArray = toIdsArray(items)
          if (!isEqual(state.eventsIdsBySport[sportId], newIdsArray)) {
            state.eventsIdsBySport[sportId] = newIdsArray
          }
          const timeTaken = Date.now() - start
          logger(
            `fetchLiveSuccess: ${timeTaken} milliseconds, events: ${items.length}`
          )
        } else {
          state.eventsIdsBySport[sportId] = toIdsArray(items)
        }
      }

      state.layouts.isLoading = false
    },
    fetchLiveError(state, action: PayloadAction<ErrorPayload>) {
      state.fetchItems.loading = false
      state.fetchItems.error = action.payload
      state.layouts.isLoading = false
    },
    toggleFilterIsTop(state) {
      if (!state.layouts.isLoading || !state.fetchItems.loading) {
        state.filters.isTop = !state.filters.isTop
        state.layouts.isLoading = true
        state.eventsIdsBySport = initialState.eventsIdsBySport
      }
    },
    resetLiveFilter(state, { payload }: PayloadAction<TResetLiveFilter>) {
      // add isResetFilter: true, if we want to reset filter for Live
      if (payload.isResetFilter) {
        state.filters = initialState.filters
      }
      state.layouts.isLoading = true
    }
  }
})

export const {
  actions: liveContainerActions,
  reducer: reducerLive,
  name: sliceKeyLive
} = liveContainerSlice

import React, { ElementRef, FC, memo, useRef, useState } from 'react'
import { Event, EventStatus } from 'betweb-openapi-axios'
import { getEventStatusType } from 'astra-core/utils/events'
import { useFeatureFlag } from 'astra-core/containers/ConfigProvider/utils'
import { EFeatureFlags } from 'astra-core/containers/ConfigProvider/types'
import { HotProbsMarketsOutcomesMapItem } from 'astra-core/containers/CommonDataProvider'
import isEqual from 'react-fast-compare'
import { useGetHotProbsSportMarketOutcomeTypes } from 'astra-core/hooks/useGetHotProbsSportMarketOutcomeTypes'

import {
  CoefficientTableCellStyled,
  EventRow as EventRowEntity,
  EventRowExpandButton
} from 'entities/event'
import { useEventRowHandleHover, useExpandEventRow } from 'features/event'
import { Outcomes } from 'pages/page-live-events/Line/components/Outcomes/Outcomes'
import { OutcomesComponentType } from 'pages/page-live-events/Line/components/Outcomes/Outcomes.types'
import { OutcomesCategories } from 'pages/page-live-events/Line/components/Outcomes/Table'

import { TableBetsRowContext } from '../../lib/context'
import { TeamWrapper } from '../teams-names'
import { EventRowCoefficient } from '../coefficient'
import { LinkedEvents } from '../linked-events'
import { OutcomesHead } from '../outcomes-head'
import { EventInfo } from '../event-info'
import { EventRowMessage } from '../message'

import { ITableColumnHeaderListProps } from './event-row.types'

const CoefficientsList: FC<{
  outcomeTypes?: HotProbsMarketsOutcomesMapItem[]
  event: Event
}> = ({ outcomeTypes, event }) => {
  return (
    <>
      {outcomeTypes?.map((coefficientData) => (
        <EventRowCoefficient
          {...coefficientData}
          event={event}
          key={coefficientData.outcomeTypesName[0]}
        />
      ))}
    </>
  )
}

export const EventRow: FC<ITableColumnHeaderListProps> = memo(
  ({ event, isMainPage, parentEvent }) => {
    const isShowAllEventProbs = useFeatureFlag(
      EFeatureFlags.SHOW_ALL_EVENT_PROBS
    )
    const isLinkedEventsEnabled = useFeatureFlag(
      EFeatureFlags.LINKED_EVENTS_ENABLED
    )
    const [areAllOpened, setAllOpened] = useState(true)
    const outcomesRef = useRef<ElementRef<typeof OutcomesCategories>>(null)
    const isSuspended = event.status === EventStatus.Suspended
    const { toggleOutcomesOpen, isOutcomesOpened } = useExpandEventRow({
      isSuspended
    })
    const {
      toggleOutcomesOpen: toggleLinkedEventsOpen,
      isOutcomesOpened: isLinkedEventsOpened
    } = useExpandEventRow({ isSuspended })

    const showLinkedEvents = isLinkedEventsOpened && isLinkedEventsEnabled

    const { handleMouseEnter, handleMouseLeave } = useEventRowHandleHover({
      eventId: event.id,
      isHoverAvailable: !isOutcomesOpened
    })

    const isLoadingOutcomes = isShowAllEventProbs
      ? !event
      : event.status === EventStatus.Suspended

    const openAll = () => {
      setAllOpened(true)
      outcomesRef?.current?.openAll()
    }

    const closeAll = () => {
      setAllOpened(false)
      outcomesRef?.current?.closeAll()
    }

    const { outcomeTypes } = useGetHotProbsSportMarketOutcomeTypes({
      sportId: event.tournament.sportId
    })

    const { eventStatusType } = getEventStatusType(event)

    return (
      <TableBetsRowContext.Provider value={{ event }}>
        <EventRowEntity
          coefficientsItems={
            <CoefficientsList event={event} outcomeTypes={outcomeTypes} />
          }
          expandedData={
            isOutcomesOpened && (
              <OutcomesHead
                areAllOpened={areAllOpened}
                closeAll={closeAll}
                event={event}
                openAll={openAll}
              />
            )
          }
          infoCellComponents={
            parentEvent ? (
              <TeamWrapper event={parentEvent}>
                {event?.eventType?.name}
              </TeamWrapper>
            ) : (
              <EventInfo
                event={event}
                isLinkedEventsOpened={isLinkedEventsOpened}
                isMainPage={isMainPage}
                toggleLinkedEventsOpen={toggleLinkedEventsOpen}
              />
            )
          }
          lastCell={
            <CoefficientTableCellStyled withExpandButton>
              <EventRowExpandButton onClick={toggleOutcomesOpen}>
                {event.outcomesCount}
              </EventRowExpandButton>
            </CoefficientTableCellStyled>
          }
          linkedEvents={
            showLinkedEvents && <LinkedEvents parentEvent={event} />
          }
          outcomesComponent={
            isOutcomesOpened ? (
              <Outcomes
                eventId={event.id}
                eventStatusType={eventStatusType}
                isLoading={isLoadingOutcomes}
                outcomesRef={outcomesRef}
                type={OutcomesComponentType.EVENTS_LIST}
              />
            ) : null
          }
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
          message={!!outcomeTypes?.length && isSuspended && <EventRowMessage />}
        />
      </TableBetsRowContext.Provider>
    )
  },
  isEqual
)
